import { Paper } from 'base-components';
import CableGeneralTabForm from './CableGeneralTabForm';

const handleUpdate = (event) => {
  event.preventDefault();
};

const CableGeneralTabPanel = () => (
  <Paper
    sx={{
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      margin: 2,
      maxWidth: '600px',
    }}
  >
    <CableGeneralTabForm
      onSubmit={handleUpdate}
      cableTypeList={[]}
      connectorTypeList={[]}
    />
    {/* TODO: IPP Table */}
  </Paper>
);

export default CableGeneralTabPanel;
