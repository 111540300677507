import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid, LoadingButton } from 'base-components';
import { CableTypeRow } from 'domain/AppComponents/cabletype/CableTypeTableLogic';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { ConnectorTypeRow } from 'domain/Apps/connectortype/ConnectorTypeTableLogic';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

type CableGeneralTabFormFields = {
  cableName: string;
  length: string;
  colorBand: string;
  characteristics: string;
  jira: string;
  cableType: { value: number; label: string } | null;
  connectorType1: { value: number; label: string } | null;
  connectorType2: { value: number; label: string } | null;
};

type CableGeneralTabProps = {
  cableTypeList: CableTypeRow[];
  connectorTypeList: ConnectorTypeRow[];
  onSubmit: ({
    cableName,
    cableType,
    connectorType1,
    connectorType2,
    length,
    colorBand,
    characteristics,
    jira,
  }: {
    cableName: string;
    cableType: { value: number; label: string };
    connectorType1: { value: number; label: string };
    connectorType2: { value: number; label: string };
    length: string;
    colorBand: string;
    characteristics: string;
    jira: string;
  }) => void;
};

const CableGeneralTabForm: React.FC<CableGeneralTabProps> = ({
  cableTypeList,
  connectorTypeList,
  onSubmit,
}: CableGeneralTabProps) => {
  const formMethods = useForm<CableGeneralTabFormFields>({});
  const {
    formState: { isSubmitting },
  } = formMethods;
  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormAutocomplete
        name="cableType"
        translationKey="cables.cableType"
        fullWidth
        options={cableTypeList}
        getOptionLabel={(option) =>
          `${option.cableTypeName} (${option.cableTypeId})`
        }
      />
      <FormAutocomplete
        name="connectorType1"
        translationKey="cables.connectorType"
        translationOptions={{ connectorType: '1' }}
        fullWidth
        options={connectorTypeList}
        getOptionLabel={(option) =>
          `${option.connectorTypeName} (${option.connectorTypeId})`
        }
      />
      <FormAutocomplete
        name="connectorType2"
        translationKey="cables.connectorType"
        translationOptions={{ connectorType: '2' }}
        fullWidth
        options={connectorTypeList}
        getOptionLabel={(option) =>
          `${option.connectorTypeName} (${option.connectorTypeId})`
        }
      />
      <FormTextField
        name="colorBand"
        translationKey="cables.colourBand"
        fullWidth
      />
      <FormTextField
        name="cableCharacteristics"
        translationKey="cables.characteristics"
        fullWidth
      />
      <FormTextField name="length" translationKey="cables.length" fullWidth />
      <FormTextField
        name="comment"
        translationKey="common.textfields.comment"
        fullWidth
      />
      <FormTextField name="jira" translationKey="cables.jira" fullWidth />
      <LoadingButton
        loading={false}
        translationKey="common.buttons.clone"
        onClick={() => {}}
        variant="outlined"
      />
      <LoadingButton
        loading={isSubmitting}
        translationKey="common.buttons.update"
        onClick={() => {}}
        variant="contained"
      />
      <Grid container item spacing={3}>
        <Grid item>
          <ModifyBy username="user" />
        </Grid>
        <Grid item>
          <ModifyDate date="date" />
        </Grid>
      </Grid>
    </Form>
  );
};
export default CableGeneralTabForm;
